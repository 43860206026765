.header {
    background: #EDEDED;
    padding: 1rem;
    box-shadow: 0 5px 20px -8px #858585;
    -webkit-box-shadow: 0 5px 15px -8px #858585;
    -moz-box-shadow: 0 5px 15px -8px #858585;
    transition: height .3s;
}

.header .logo {
    padding-bottom: 1rem;
    text-align: center;
}

.header .logo img {
    max-height: 85px;
    transition: height .3s;
}

.header .title {
    color: #E60019;
    border-top: 1px solid #e60019;
    border-bottom: 1px solid #e60019;
    padding: 0.5rem;
    text-align: center;
}

.header .title h6 {
    font-size: 12pt;
    margin: 0;
}


@media only screen and (max-width: 480px) {
    .header {
        z-index: 1;
    }

    .header .logo img {
        max-height: 45px;
    }
}


