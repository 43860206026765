.range-question {
    /*display: flex;*/
    /*justify-content: center;*/
    /*flex-direction: column;*/
}

.range-question .options {
    width: fit-content;
    margin: auto;
}

.range-question .desc {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    color: #E60019;
}

.range-question .option {
    height: 40px;
    width: 40px;
    font-family: isans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    display: inline-flex;
    border-radius: 5px;
    margin: 1px;
    justify-content: center;
    align-items: center;
    background: #EDEDED;
    color: #A2A2A2;
    border: none;
}

.range-question .option.active {
    background: #FFCBD0;
    color: #E60019;
}

.range-question .not-used {
    margin: .75rem 0;
    justify-content: end;
}

.range-question .not-used button {
    font-family: isans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    display: inline-flex;
    border-radius: 5px;
    margin: 1px;
    justify-content: center;
    align-items: center;
    background: #EDEDED;
    color: #A2A2A2;
    border: none;
    font-size: 10pt;
    padding: 5px 20px;
}

.range-question .not-used button.active {
    background: #FFCBD0;
    color: #E60019;
}

@media only screen and (max-width: 480px) {
    .range-question .option {
        height: 40px;
        width: 30px;
    }
}