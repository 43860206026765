@font-face {
    font-family: isans;
    src: url(./../fonts/iransans/iransans.ttf);
}

body {
    margin: 0;
    font-family: 'isans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p, a, button,span {
    font-family: isans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

a, p {
    font-size: 11pt;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #EDEDED;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #e60019;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #e6001999;
}

/* ############################### Custom ################################# */

.rtl {
    direction: rtl !important;
}

.ltr {
    direction: ltr !important;
}

.btn {
    font-family: 'isans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 11pt !important;
    border-radius: 10px !important;
    outline: none !important;
}

.main-header-context {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

.main-body-context {
    margin-top: 200px;
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}

.text-main-color {
    color: #E60019;
}

.btn-main-color {
    background: #E60019 !important;
    color: #fff !important;
}

.btn-main-outline-color {
    color: #E60019 !important;
    border: 1px solid #E60019 !important;
    min-width: 200px;
}

@media only screen and (max-width: 480px) {
    .main-body-context {
        margin-top: 150px;
        margin-bottom: 50px;
    }
}
