.desc-question textarea {
    border: 1px dashed #707070;
    background: #eeeeee;
    font-family: isans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    outline: none !important;
    resize: none;
    border-radius: 10px;
}

.desc-question textarea:focus {
    border: 1px dashed #707070;
    background: #eeeeee;
    outline: none !important;
}

.desc-question textarea::placeholder {
    font-size: 11pt;
}

.desc-question .desc-len {
    font-size: 10pt;
}

@media only screen and (max-width: 480px) {
    .desc-question textarea:focus {
        z-index: 2;
        position: relative;
    }
}