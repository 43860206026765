.main-divider {
    display: flex;
    direction: rtl;
    align-items: center;
    padding: 0 5px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 5;
}

.main-divider .divider-line {
    height: 2px;
    width: 100%;
    margin-right: auto;
    background: #D32D29;
}

.main-divider .divider-text {
    font-size: 13pt !important;
    color: #E60019;
    padding: 0 10px;
    line-height: 1.7;
    white-space: nowrap;
    clear: both;
}

.main-divider .divider-text:focus {
    box-shadow: none !important;
}


@media only screen and (max-width: 480px) {
    .main-divider {
        background: #fff;
    }

    .main-divider .divider-line {

    }

    .main-divider .divider-text {
        font-size: 10pt;
        margin: 0;
        text-align: center;
    }
}